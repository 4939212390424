import React from 'react';
import LessonItem from './LessonItem';
import { connect } from 'react-redux';
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Lessons extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeLesson: this.props.lesson, lessons: this.props.lessons, keyboard: this.props.keyboard_layout, showCustomLessonCreate: true };
    }

    handleLessonChange = (event, data) => {
        event.preventDefault();
        this.props.changeLesson(data);
    }

    handleCustomLessonAdd= (event) =>
    {
        event.preventDefault();
        this.props.triggerAddLesson();
    }

    showCustomLesson = () =>
    {
        return this.props.dataService.getLessons(this.props.keyboard_layout).filter(x => x.identifier).length === 0;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.activeLesson !== this.props.lesson) {
            this.setState({ activeLesson: this.props.lesson });
        }

        if (prevState.keyboard !== this.props.keyboard_layout) {
            this.setState({ keyboard: this.props.keyboard_layout, lessons: this.props.lessons });
        }
    }

    render() {
        return (
            <div className="list-group">
                {this.props.dataService.getLessons(this.props.keyboard_layout).map((item) =>
                    <LessonItem key={item.lesson} onLessonChange={this.handleLessonChange.bind(this)} newCharacters={item.newCharacters} lesson={item.lesson} result={this.props.dataService.getBestResultforLesson(this.props.keyboard_layout, item.lesson)} active={item.lesson === this.state.activeLesson} isCustom={item.identifier} />
                )}
                { this.showCustomLesson() &&
                <a href="#" onClick={this.handleCustomLessonAdd.bind(this)}  style={{ backgroundColor: 'greenyellow' }} className="list-group-item clearfix">
                    <FontAwesomeIcon icon={faPlus} className="small-margin-right" /> Add New</a>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        isFinished: state.isFinished,
        keyboard_layout: state.keyboard_layout,
        dataService: state.dataService,
        dummyCounter: state.dummyCounter
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        changeLesson: (lesson) => {
            dispatch({ type: "CHANGE_LESSON", lesson })
        },
        triggerAddLesson: (lesson) => {
            dispatch({ type: "CREATE_NEW_LESSON_TRIGGER", lesson })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Lessons);