import React from 'react';

class Banner extends React.Component {
    render() {
        return (
            <div className="banner">
			<div className="container">
				<h1>Welcome to Typing Warrior!</h1>
				<p>Practise your Thai or English touch typing skills. With this tutor, you can improve your typing skill with our lessons and tests. This application is fun and totally free and works for both English keyboards and Thai keyboards. 
					Use our interactive typing lessons to track your progress and test your speed and accuracy with lessons you create yourself.</p>
			</div>
		</div>
        )
    }
}

export default Banner;