import React from 'react';
import {connect} from 'react-redux';

class KeyboardButtons extends React.Component {
    constructor(props) {
        super(props);

        this.togglePlaySound.bind(this);
        this.toggleShowColors.bind(this);
        this.toggleShowKeyboardsLabels.bind(this);
        this.restartLesson.bind(this);
    }

    toggleShowKeyboardsLabels = (event) =>
    {
        this.props.toggleKeyboard();
    }

    toggleShowColors = (event) =>
    {
        this.props.toggleColors();
    }

    togglePlaySound = (event) =>
    {
        this.props.togglePlaySound();
    }

    restartLesson = (event) =>
    {
        this.props.changeLesson(this.props.lesson);
    }

    render() {
        const keyboardClass = "launch-button "+(this.props.isShowKeyboardLabels ? "color-light-orange" : "");
        const colorClass = "launch-button "+(this.props.isShowColor ? "color-light-orange" : "");
        const soundClass = "launch-button "+(this.props.isPlaySound ? "color-light-orange" : "");

        return (
            
            <div className="keyboard-buttons">
                <button id="keyboardButton" onClick={this.toggleShowKeyboardsLabels} className={keyboardClass} data-toggle="tooltip" data-placement="top" title="Toggle show keyboard labels"></button>
                <button id="colorButton" onClick={this.toggleShowColors}  className={colorClass} data-toggle="tooltip" data-placement="top" title="Toogle show colors"></button>
                <button id="soundButton"  onClick={this.togglePlaySound}  className={soundClass} data-toggle="tooltip" data-placement="top" title="Toogle play sound on key strokes"></button>
                <button id="reloadButton" onClick={this.restartLesson}  className="launch-button"  data-toggle="tooltip" data-placement="top" title="Restart lesson"></button>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        isPlaySound: state.isPlaySound,
        isShowKeyboardLabels : state.isShowKeyboardLabels,
        isShowColor : state.isShowColor
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        changeLesson: (lesson) => {
            dispatch({ type: "CHANGE_LESSON", lesson })
        },
        toggleColors: () => {
            dispatch({ type: "TOGGLE_COLORS"})
        },
        toggleKeyboard: () => {
            dispatch({ type: "TOGGLE_KEYBOARD"})
        },
        togglePlaySound: () => {
            dispatch({ type: "TOGGLE_PLAYSOUND"})
        }
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(KeyboardButtons);