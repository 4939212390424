import React from "react";
import TopNavbar from '../components/layout/TopNavbar';
import Banner from '../components/layout/Banner';
import MainFooter from '../components/layout/MainFooter';
import CallToAction from '../components/layout/CallToAction';
import TextArea from '../components/layout/TextArea';

const DefaultLayout = ({children}) => (
     <div className="wrapper">
         <TopNavbar />
         <Banner />
         {children}
         <MainFooter />
    </div>
);

export default DefaultLayout;