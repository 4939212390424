import React from 'react';
import {connect} from 'react-redux';

class KeyboardLayoutSwitch extends React.Component {
	constructor(props)
	{
		super(props);
		this.switchToEnglish.bind(this);
		this.switchToThai.bind(this);
	}

	switchToEnglish = (event) =>
	{
		event.preventDefault();
		this.props.switchKeyboard("EN");
	}

	switchToThai = (event) =>
	{
		event.preventDefault();
		this.props.switchKeyboard("TH");
	}

	render() {
		return (
			<div className="keyboard-layout-button">
				<div className="dropdown">
					<button type="button" className="btn btn-primary dropdown-toggle" data-toggle="dropdown">
					Keyboard layout : {this.props.keyboard_layout}</button>
					<div className="dropdown-menu">
						<a className={this.props.keyboard_layout === "EN" ? "dropdown-item active" : "dropdown-item"} onClick={this.switchToEnglish} href="#">English (EN)</a>
						<a className={this.props.keyboard_layout === "TH" ? "dropdown-item active" : "dropdown-item"} onClick={this.switchToThai}  href="#">Thai (TH)</a>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
    return {
		keyboard_layout: state.keyboard_layout,
		dummyCounter: state.dummyCounter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        switchKeyboard: (result) => {
            dispatch({ type: "SWITCH_KEYBOARD_LAYOUT", result })
        }
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(KeyboardLayoutSwitch);