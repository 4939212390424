import React from 'react';
import {Link} from 'react-router-dom'

class MainFooter extends React.Component {
    render() {
        return (
            <footer>
			<div className="container">
            <p><Link to={"/"}> Home </Link> | <Link to={"/terms-and-conditions"}> Terms and conditions </Link></p>
				<p className="copy-right">Copyright &copy; 2020 &nbsp;
					<a href="#">Fredrik Rudberg</a> | All rights reserved. </p>
			</div>
		</footer>
        )
    }
}

export default MainFooter;