import React from 'react';
import routes from './routes'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

export default () => (
  <Router>
      <div>
      {routes.map((route, index) => {
        return (
          <Switch>
          <Route key={index} path={route.path} exact={route.exact} component={props => {
              return(
                <route.layout {...props}>
                  <route.component {...props} />
                </route.layout>
              );
            }}
          />
          </Switch>
        );
      })}
      </div>
  </Router>
)