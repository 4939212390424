import React from 'react';
import { connect } from 'react-redux';

class CreateLesson extends React.Component {
    constructor(props) {
        super(props);
        this.startLessonEvent = this.startLessonEvent.bind(this);
        this.state = { isStarted: this.props.isStarted, canSave: false, txtName: '', txtLesson: '', isEdit: false};
        this.handleNameOnChange.bind(this);
        this.handleTextOnChange.bind(this);
        this.handleFormSubmit.bind(this);
    }

    startLessonEvent(event) {
        event.preventDefault();
        this.props.startLesson("test");
    }

    componentDidMount()
    {
        if(this.props.existingLesson)
        {
            this.setState({txtName: this.props.existingLesson.lesson,txtLesson: this.props.existingLesson.text.join(""), isEdit: true});
        }
    }

    handleNameOnChange = (event) =>
    {
        console.log("name was changed");
        this.setState({txtName: event.target.value});
        this.setState({canSave: !(this.state.txtName.trim() === '' || this.state.txtLesson.trim() === '')});
    }

    handleTextOnChange = (event) =>
    {
        console.log("text was changed");
        this.setState({txtLesson: event.target.value});
        this.setState({canSave: !(this.state.txtName.trim() === '' || this.state.txtLesson.trim() === '')});
    }

    handleFormSubmit = (event) =>
    {
        event.preventDefault();
        
        if(this.state.isEdit)
            this.props.dataService.updateCustomLesson(this.state.txtName,this.props.keyboard_layout,this.state.txtLesson.split(""));
        else 
            this.props.dataService.addCustomLesson(this.state.txtName,this.props.keyboard_layout,this.state.txtLesson.split(""));

        var lessonObj = this.props.dataService.getLessons(this.props.keyboard_layout).filter(x => x.identifier !== undefined);
        this.props.createLessonCompleted(lessonObj[0].lesson);
    }

    handleCancel = (event) =>
    {
        this.props.cancelCreateLesson();
    }

    render() {
        return (
            <div className="createLesson">
                <div style={{width:'100%',height:'100%'}}>
                    <form className="border p-5" onSubmit={this.handleFormSubmit}>
                        <input disabled={this.state.isEdit} type="text" id="defaultContactFormName" value={this.state.txtName} onChange={this.handleNameOnChange} maxLength="15" className="form-control mb-4" placeholder="Lesson Name" />
                        <div className="form-group">
                            <textarea className="form-control rounded-0" value={this.state.txtLesson} maxLength="1000" onChange={this.handleTextOnChange} id="exampleFormControlTextarea2" rows="11" placeholder="Lesson Text (Max 1000 Characters)"></textarea>
                        </div>
                        <div className="btn-toolbar float-right">
                            <button disabled={!this.state.canSave} type="Submit" className="btn btn-primary mr-3">Save</button>
                            <button type="button" onClick={this.handleCancel} className="btn btn-danger">Cancel</button>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        cancelCreateLesson: () => {
            dispatch({ type: "CANCEL_CREATE_NEW_LESSON"})
        },
        createLessonCompleted: (name) => {
            dispatch({ type: "CREATE_LESSON_COMPLETED",name})
        },
        updateLessonCompleted: (name) => {
            dispatch({ type: "UPDATE_LESSON_COMPLETED",name})
        }
    };
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        isStarted: state.isStarted,
        dataService: state.dataService,
        keyboard_layout : state.keyboard_layout
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateLesson);