import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faLinkedinIn, faPinterest  } from '@fortawesome/free-brands-svg-icons'

class TextArea extends React.Component {
    render() {
        return (
            <div className="text-area">
			<article>
				<h2>Practicing Touch Typing </h2>
				<p>How fast and accurate can you type? Typing training can be fun but there is more to it than that. Being able to use all your 10 fingers 
					can be of great importance if you are working in front of a computer. Being able to touch type like a typing warrior, significantly 
					increase your productivity and make you less tired. Here is an <a href="https://www.educatingmatters.co.uk/blog/touch-typing/">interesting article on why you should teach your children to touch type</a> Typing in Thai is with its 44 consonants is especially challenging. This is actually why I created this application in the first place. 
					The lessons in this application are created with the help of frequency lists, and new keys and characters are introduced 
					gradually in the type lessons, forming real words.</p>
				<br/>
				<h2>How To Use Typing Warrior</h2>

				<p>
				Practice your typing and you will soon become an expert. After you have completed a lesson you will see either 
				a green pass mark or a failed mark to the right of the lesson, this is the same for both the Thai and English keyboard layout. 
				This indicates how well you did in terms of speed and accuracy by measuring time spent and words per minute. 
				To pass a typing test you need your words per minute value to exceed 20 and your accuracy needs to be above 90%. 
				Your best score will be saved on the website, if you want to persist your score and come back to continue where you left of you can do that by signing in with your Google account. 
				If you prefer taking a Thai typing test then switch the keyboard in the keyboard dropdown.</p>
				<br/>
				<p>
					The lessons are initially easy but are gradually increasing in difficulty. When you have completed all lessons feel free
					to use the custom lesson at the bottom of the lesson list. This is a special lesson where you can copy and paste a custom
					text to use as typing practice material.
				</p>
				<br/>
				<p>Typing Warrior is an effective application for learning touch typing. We support both English and Thai keyboard layout.
					If you want to see other keyboards feel free to request changes to this site by contacting <a href="https://www.hirequalitysoftware.com">www.hirequalitysoftware.com</a>. </p>
				<br/>
				<br />
				<br />
				<div style={{display:'inline'}}>
					<div style={{display:'table'}} className="share-btns__list">
					    <h4 style={{display:'table-cell', verticalAlign:'middle', paddingRight:'5px',color:'black'}}>SHARE:</h4>
						<div style={{textAlign: 'center',marginRight:'10px',paddingLeft:'10px'}} className="share-btns__item facebook-item">
							<a className="share-btns__link" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftypingwarrior.com" target="_blank" rel="nofollow" title="Share on Facebook">
								<FontAwesomeIcon icon={faFacebookF} />
							</a>
						</div>
						<div style={{ textAlign: 'center',marginRight:'10px'  }} className="share-btns__item facebook-item">
							<a className="share-btns__link" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Ftypingwarrior.com" target="_blank" rel="nofollow" title="Share on Facebook">
								<FontAwesomeIcon icon={faTwitter} />
							</a>
						</div>
						<div style={{ textAlign: 'center',marginRight:'10px' }} className="share-btns__item facebook-item">
							<a className="share-btns__link" href="http://www.linkedin.com/shareArticle?mini=true&amp;url=https%3A%2F%2Ftypingwarrior.com" target="_blank" rel="nofollow" title="Share on Facebook">
								<FontAwesomeIcon icon={faLinkedinIn} />
							</a>
						</div>
						<div style={{ textAlign: 'center',marginRight:'10px' }} className="share-btns__item facebook-item">
							<a className="share-btns__link" href="https://www.pinterest.com/pin/create/button/?url=https%3A%2F%2Ftypingwarrior.com" target="_blank" rel="nofollow" title="Share on Facebook">
								<FontAwesomeIcon icon={faPinterest} />
							</a>
						</div>
					</div>
				</div>
			</article>
		</div>
        )
    }
}

export default TextArea;