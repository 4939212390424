import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


class LessonItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {active: props.active, lesson: props.lesson, result: props.result}
    }


    componentDidUpdate(prevProps, prevState)
    {
       if(prevState.active !== this.props.active)
       {
           this.setState({
               active: this.props.active
           });
       }
    }

    normalClass()
    {
        return "list-group-item clearfix ";
    }

    activeClass()
    {
        if(this.state.active === true)
            return "active ";
        else
            return "";
    }

    render() {
        return (
            <a href="#" onClick={((e) => this.props.onLessonChange(e, this.props.lesson))} className={this.normalClass() + this.activeClass()}>
                {this.props.isCustom ? "" : "Lesson"} {this.props.lesson} 
            {
                this.props.newCharacters.length > 0 ?
        <span className="new-characters-lesson">( {this.props.newCharacters.join(',')} ) </span> : ""

            }
                         <span className="float-right">
                         { this.props.result ? this.props.result.passed ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} /> : <span></span> }
                </span>
            </a>
        )
    }
}

export default LessonItem;