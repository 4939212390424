import React from 'react';
import { connect } from 'react-redux';
import soundfile from '../../audio/typewriter-key-1.mp3'

Array.prototype.contains = function (obj) {
    var i = this.length;
    while (i--) {
        if (this[i] === obj) {
            return true;
        }
    }
    return false;
}

class Keyboard extends React.Component {
    constructor(props) {
        super(props);
        this.emptyKey = "\u00A0";
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.topline = [];
        this.bottomline = [];
        this.keyboardMap = this.props.keyboard;
        this.keytextMap = [];
        this.audio = [new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile), new Audio(soundfile)]
        this.audioCounter = 1;
        this.wrongCharacter = "";

        for (var i = 0; i < 30; i++) {
            this.topline.push({ value: this.emptyKey, classname: "" });
            this.bottomline.push({ value: this.emptyKey, classname: "" });
        }

        this.state = { dummyCounter: this.props.dummyCounter, showWrongCharacter: false, wrongCharacter: "", lesson: this.props.lesson, currentInput: 0, currentInputKeys: 0, wrongInput: 0, isShowColor: this.props.isShowColor }
        this.lessonMap = this.props.dataService.getLesson(this.props.keyboard_layout, this.props.lesson);
        this.position = 30;
    }

    componentDidMount() {
        document.addEventListener("keydown", this.handleKeyPress);
        this.createTopLineData();
        this.AddLessonToArea();
        this.startLesson();
        this.startClock();
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyPress);
        this.stopLesson();
        this.clearKeyStyling(this.keyboardMap, "color-grey");
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.lesson !== this.props.lesson || prevState.dummyCounter !== this.props.dummyCounter) {
            this.keyboardMap = this.props.keyboard;
            this.lessonMap = this.props.dataService.getLesson(this.props.keyboard_layout, this.props.lesson);

            this.topline = [];
            this.bottomline = [];

            for (var i = 0; i < 30; i++) {
                this.topline.push({ value: this.emptyKey, classname: "" });
                this.bottomline.push({ value: this.emptyKey, classname: "" });
            }

            this.setState({ lesson: this.props.lesson, currentInputKeys: 0, currentInput: 0 });
            this.AddLessonToArea();
        }

        if (prevState.isShowColor !== this.props.isShowColor) {
            this.setState({ isShowColor: this.props.isShowColor });
        }
    }

    changeCurrentInputShade = () => {
        var textAreaSquare = this.bottomline[this.state.currentInput];
        var nextCorrectCharacter = this.lessonMap.text[this.state.currentInputKeys];
        var shiftKeys = [];

        var mainKey = (nextCorrectCharacter === ' ') ? this.keyboardMap.find(x => x.code === 32) : this.keyboardMap.find(({ title }) => title.contains(nextCorrectCharacter));
        setTimeout(() => {
            if (textAreaSquare.classname === "")
                textAreaSquare.classname = "color-grey";

            if (this.props.isShowKeyboardLabels) {
                // Toggle main key        
                if (mainKey) {
                    if (mainKey.classref.indexOf("color-grey") === -1)
                        mainKey.classref.push("color-grey");

                    // Toggle shift
                    if (mainKey.title[0] === nextCorrectCharacter) {
                        shiftKeys = this.keyboardMap.filter(x => x.code === 16);
                        shiftKeys.forEach((item, i) => {
                            if (item.classref.indexOf("color-grey") === -1)
                                item.classref.push("color-grey");
                        });
                    }
                }
                else
                    this.clearKeyStyling(this.keyboardMap, "color-grey");
            }
            this.forceUpdate();
        }, 400);
        this.clearKeyStyling(this.keyboardMap, "color-grey");
        textAreaSquare.classname = "";
        this.forceUpdate();
    }

    startLesson = () => {
        this.intervalID = setInterval(this.changeCurrentInputShade, 800)
    }


    stopLesson = () => {
        clearInterval(this.intervalID)
    }

    AddLessonToArea() {
        this.topLineData.slice(0, 30).reverse().forEach((item, i) => {
            if (item === ' ') {
                this.topline.unshift({ value: this.emptyKey, classname: "" });
                this.topline.pop();
            }
            else {
                var result = this.keyboardMap.find(({ title }) => title.contains(item[0]));
                if (result !== undefined) {
                    this.topline.unshift({ value: item, classname: result.classref[1] });
                }
                else {
                    this.topline.unshift({ value: item, classname: "" });
                }
                this.topline.pop();
            }
        });
        this.forceUpdate();
    }

    createTopLineData() {
        this.topLineData = []

        var position = 0;
        for (var i = 0; i < this.lessonMap.text.length; i++) {
            if (i === 0)
                this.topLineData.push(this.lessonMap.text[i]);
            else {
                if (this.isSpecialCharacter(this.lessonMap.text[i])) {
                    this.topLineData[position] = this.topLineData[position] + this.lessonMap.text[i];
                }
                else {
                    this.topLineData.push(this.lessonMap.text[i]);
                    position = position + 1;
                }
            }
        }
    }

    toTimeString(secs) {
        var days, hours, minutes, seconds;

        if (secs == null)
            return "";
        days = (secs / 86400) >> 0;
        hours = (secs % 86400 / 3600) >> 0;
        minutes = (secs % 3600 / 60) >> 0;
        seconds = (secs % 60);
        seconds = seconds < 10 ? "0" + seconds : seconds;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        hours = hours && hours < 10 ? "0" + hours : hours;

        return "" + (days ? days + " - " : "") + (hours ? hours + ":" : "") + minutes + ":" + seconds;
    };

    getWPM() {
        var numberOfCharacters = this.lessonMap.text.length;
        var numberOfSeconds = Math.abs(new Date(this.endTime - this.startTime) / 1000).toFixed();
        return (((numberOfCharacters / 5) / numberOfSeconds) * 60).toFixed(2);
    }

    startClock() {
        this.startTime = new Date();
    }

    stopClock() {
        this.endTime = new Date();
    }

    getElapsedTimeAsString() {
        var date = new Date(this.endTime - this.startTime);
        return this.toTimeString(Math.abs(date / 1000).toFixed());
    }

    getAccuracy() {
        return (this.state.currentInputKeys / (this.state.currentInputKeys + this.state.wrongInput) * 100).toFixed(2);
    }

    getPassed() {
        return this.getAccuracy() > 90 && this.getWPM() > 20;
    }


    getCurrentInputKeys() {
        var keysToReturn = [];
        var nextCorrectCharacter = this.topline[this.state.currentInput];
        var mainKey = this.keyboardMap.find(({ title }) => title.contains(nextCorrectCharacter.value));
        if (mainKey) {
            keysToReturn.push(mainKey);

            // Shift?
            if (mainKey.title[0] === nextCorrectCharacter.value) {
                this.keyboardMap.filter(x => x.code === 16).forEach((item) =>
                    keysToReturn.push(item)
                );
            }
        }
        return keysToReturn;
    }

    clearKeyStyling(keysToClear, className) {
        keysToClear.forEach((item) => {
            if (item) {
                item.classref = item.classref.filter(x => x !== className);
            }
        });
    }

    playAudio = () => {
        if (this.props.isPlaySound) {
            if (this.audioCounter === 14)
                this.audioCounter = 0;
            this.audio[this.audioCounter].play();
            this.audioCounter++;
        }
    }

    toggleColorChangeOnKeyboard(key) {
        this.clearKeyStyling(this.keyboardMap, "color-grey");
        if (key.classref.indexOf("transparent") === -1) {
            key.classref.push("transparent");
            // this.forceUpdate();

            setTimeout(() => {
                if (key.classref.indexOf("transparent") !== -1) {
                    this.clearKeyStyling([key], "transparent");
                }
            }, 200);
        }
    }

    isSpecialCharacter = (key) => {
        return this.props.specialCharacters.contains(key);
    }

    mergeKeyValue = (oldValue, newValue) => {
        if (oldValue !== this.emptyKey)
            return oldValue + newValue;
        else
            return newValue;
    }

    handleKeyPress(event) {
        this.playAudio();

        var key = this.keyboardMap.find(({ title }) => title.contains(event.key));

        if (!key) {
            key = { code: -1, location: -1, title: [event.key], classref: [], showCharacters: 0 }
        }

        if (event.keyCode !== 16) {
            if (this.lessonMap.text[this.state.currentInputKeys] === event.key || (key.location === 54 && this.lessonMap.text[this.state.currentInputKeys] === " ")) {

                // The correct letter was inputted!
                // here we need to find out if it is a special character!
                if (this.isSpecialCharacter(event.key) && this.state.currentInput > 0) {
                    this.state.currentInput = this.state.currentInput - 1;
                    this.bottomline[this.state.currentInput] = { value: this.mergeKeyValue(this.bottomline[this.state.currentInput].value, event.key), classname: "" }
                }
                else
                    this.bottomline[this.state.currentInput] = { value: event.key, classname: "" };

                if (this.state.currentInput >= 15) {
                    if (!this.isSpecialCharacter(event.key)) {
                        this.topline.shift();

                        if (this.topLineData[this.position]) {
                            var result = this.keyboardMap.find(({ title }) => title.contains(this.topLineData[this.position][0]));
                            this.topline.push({ value: this.topLineData[this.position], classname: result ? result.classref[1] : "" });
                            this.position++;
                        }
                        else
                            this.topline.push({ value: this.emptyKey, classname: "" });

                        this.bottomline.shift();
                        this.bottomline.push({ value: this.emptyKey, classname: "" });
                    }
                }
                else
                    this.setState({ currentInput: this.state.currentInput + 1 })

                this.setState({ currentInputKeys: this.state.currentInputKeys + 1 });
            }
            else  // The wrong key was pressed.
                this.setState({ wrongInput: this.state.wrongInput + 1 })

            // Check if lesson is finished
            if (this.state.currentInputKeys === this.lessonMap.text.length) {
                this.stopClock();
                this.props.finishLesson({ lesson: this.props.lesson, accuracy: this.getAccuracy(), timeElapsed: this.getElapsedTimeAsString(), wpm: this.getWPM(), passed: this.getPassed() });
            }
        }
        this.toggleColorChangeOnKeyboard(key);



        if (key.code < 1) {
            this.setState({ showWrongCharacter: true, wrongCharacter: event.key })
            setTimeout(() => {
                this.setState({ showWrongCharacter: false, wrongCharacter: "" })
            }, 350);
        }
        event.preventDefault();
    }

    render() {
        return (
            <div style={{ position: 'relative' }}>
                <div id="typing-area">
                    <div style={this.state.showWrongCharacter && this.state.wrongCharacter.length < 3 ? { display: 'block' } : { display: 'none' }} className="keyboard-wrong-character">{this.state.wrongCharacter}</div>
                    <table className="keyboard-table">
                        <tbody>
                            <tr>
                                {
                                    this.topline.map((item, i) => this.state.isShowColor ? <td style={{ minWidth: '20px' }} key={i} className={"letterCell " + item.classname}>{item.value}</td> :
                                        <td style={{ minWidth: '20px' }} key={i} className={"letterCell "}>{item.value}</td>
                                    )}
                            </tr>
                            <tr>
                                {
                                    this.bottomline.map((item) => <td style={{ minWidth: '20px' }} key={item.key} className={item.classname}>{item.value}</td>
                                    )}
                            </tr>

                        </tbody>
                    </table>
                </div>
                <div id="keyboard-area">
                    <ul id="keyboard">
                        {this.keyboardMap.map((item, i) =>
                            <li key={i} id={item.id} className={item.classref.join(' ')}><span className="ShowHideLetter">
                                {
                                    item.showCharacters > 1 ?
                                        item.title.map((t, x) => <div key={x}>{this.props.isShowKeyboardLabels ? t : ""}</div>) :
                                        <div>{this.props.isShowKeyboardLabels ? item.title[0] : ""}</div>
                                }
                            </span></li>
                        )}
                    </ul>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        dataService: state.dataService,
        keyboard: state.keyboard,
        keyboard_layout: state.keyboard_layout,
        isPlaySound: state.isPlaySound,
        isShowKeyboardLabels: state.isShowKeyboardLabels,
        isShowColor: state.isShowColor,
        specialCharacters: state.specialCharacters,
        dummyCounter: state.dummyCounter
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        finishLesson: (result) => {
            dispatch({ type: "FINISHED_LESSON", result })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Keyboard);