import React from 'react';
import {connect} from 'react-redux';
import Keyboard from './Keyboard';
import KeyboardLayoutSwitch from './KeyboardLayoutSwitch';
import Lessons from './Lessons';
import ScoreCard from './ScoreCard';
import KeyboardButtons from './KeyboardButtons';
import LessonTimer from './LessonTimer';
import StartLesson from './StartLesson';
import FinishedLesson from './FinishedLesson';
import CreateLesson from './CreateLesson';
import CallToAction from '../layout/CallToAction';
import TextArea from '../layout//TextArea';

class KeyboardHolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {editLesson: {},showScreen: props.isFinished ? "finishLesson" : props.isStarted ? "keyboard" : props.isCreatingNewLesson ? "createLesson" : "startLesson" }
    }


    componentDidUpdate(prevProps, prevState)
    {
        if((prevProps.editLesson.identifier !== this.props.editLesson.identifier) || (prevProps.isStarted !== this.props.isStarted) || (prevProps.isFinished !== this.props.isFinished) || (prevProps.isCreatingNewLesson !== this.props.isCreatingNewLesson))
        {
            this.setState({ showScreen: this.props.isFinished ? "finishLesson" : this.props.isStarted ? "keyboard" : this.props.isCreatingNewLesson ? "createLesson" : "startLesson"})
            if(this.props.editLesson.identifier !== undefined && prevProps.editLesson.identifier !== this.props.editLesson.identifier)
            {
                this.setState({showScreen: "editLesson"});
            }
        }
    }

    getChildComponent = () =>
    {
        if(this.state.showScreen === "keyboard")
        {
            return (                
            <div>
                <div className="keyboard-instruments">
                        <LessonTimer />
                        <KeyboardButtons />
                </div>
                <div id="center-keyboard-column">
                    <div id="center-keyboard-column-inner">
                        <Keyboard/>
                    </div>
                </div>
            </div>);
        }

        else if(this.state.showScreen === "finishLesson")
        {
            return (<FinishedLesson result={this.props.result} lesson={this.lesson} />);
        }

        else if(this.state.showScreen === "createLesson")
        {
            return (<CreateLesson />);
        }

        else if(this.state.showScreen === "editLesson")
        {
            return (<CreateLesson existingLesson={this.props.editLesson} />);
        }
        else if(this.state.showScreen === "startLesson")
        {
            return (<StartLesson lesson={this.props.lesson} />);
        }
        else
            return "Fredrik Was Here";
    }

    render() {
        return (
            <>
                <div>
                    <KeyboardLayoutSwitch />
                    <div className="typing-area" id="typing-area">
                        <div className='row-flex'>
                            <div className='column-flex'>
                                <Lessons />
                            </div>
                            <div className='column-flex-mid'>
                                {this.getChildComponent()}
                            </div>
                            <div className='column-flex'>
                                <ScoreCard />
                            </div>
                        </div>
                    </div>
                </div>
                <TextArea />
                <CallToAction />
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        isStarted: state.isStarted,
        isFinished: state.isFinished,
        result: state.result,
        isCreatingNewLesson: state.isCreatingNewLesson,
        editLesson: state.editLesson,
    }
}

export default connect(mapStateToProps)(KeyboardHolder);
