import React from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'

class StartLesson extends React.Component {
    constructor(props) {
        super(props);
        this.startLessonEvent = this.startLessonEvent.bind(this);
        this.state = { isStarted: this.props.isStarted };
    }

    startLessonEvent(event) {
        event.preventDefault();
        this.props.startLesson("test");
    }

    deleteLesson = (event) =>
    {
        event.preventDefault();
        this.props.dataService.deleteCustomLesson(this.props.lesson,this.props.keyboard_layout);
        this.props.lessonDeleted();
    }

    editLesson = (event) =>
    {
        event.preventDefault(); 
        this.props.editLesson(this.props.dataService.getLesson(this.props.keyboard_layout,this.props.lesson));
    }

    isCustomLesson = () =>
    {
        return this.props.dataService.getLesson(this.props.keyboard_layout,this.props.lesson).identifier !== undefined;
    }

    render() {
        return (
            <div className="startLesson">
                { this.isCustomLesson() && 
                    <div className="button-list">
                        <button onClick={this.editLesson} className="btn btn-success">
                            <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button className="btn btn-danger confirm-delete" title="Delete" data-toggle="modal" data-target="#confirmDeleteModal">
                            <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                    </div>
                }
                <div className="start-lesson-text-area">
                    <h2>{!this.isCustomLesson() && "Lesson"} {this.props.lesson}: <span style={{whiteSpace: 'nowrap'}}>Press start to begin</span></h2>
                    <p>
                        <button type="button" onClick={this.startLessonEvent} className="btn btn-primary btn-lg">
                            Start Typing
                        </button>
                    </p>
                </div>
                <div className="modal fade" id="confirmDeleteModal" tabIndex="-1" caller-id="" role="dialog" aria-labelledby="confirmDeleteModalLabel"
                    aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-body confirm-delete">
                                Are you sure you want to delete this lesson?</div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                                <button type="button" onClick={this.deleteLesson} className="btn btn-danger" data-dismiss="modal" id="confirmDeleteButtonModal">Delete</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        startLesson: (lesson) => {
            dispatch({ type: "START_LESSON", lesson })
        },
        lessonDeleted: () => {
            dispatch({ type: "LESSON_DELETED" })
        },
        editLesson: (lesson) => {
            dispatch({type: "EDIT_LESSON", lesson})
        }
    };
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        isStarted: state.isStarted,
        keyboard_layout: state.keyboard_layout,
        dataService: state.dataService
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(StartLesson);