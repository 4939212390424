import LessonService from './LessonService';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

class DataService {
    constructor() {
        this.lessonService = new LessonService();
        this.bestResult = [{identifier:"EN", result: []}, {identifier:"TH", result:[]}]
        this.db = app.firestore();
        this.user_doc = uid => this.db.doc(`user_docs/${uid}`);
        this.customLessons = [];
    }

    getLesson = (keyboard_layout, lesson) => {
        var result;
        var lessons;

        if(keyboard_layout === "EN")
            lessons = this.lessonService.GetEnglishLessons();
        else if (keyboard_layout === "TH")
            lessons = this.lessonService.GetThaiLessons();
        else
        {
            console.log("the keyboard layout for this lesson was not found");
            return;
        }

        if (this.customLessons && this.customLessons.find(x => x.identifier === keyboard_layout))
            lessons.push(this.customLessons.find(x => x.identifier === keyboard_layout));

        result = lessons.find(x => x.lesson === lesson);
        return result;
    }

    getLessons = (keyboard_layout) => {
        var lessons;

        if(keyboard_layout === "EN")
            lessons =  this.lessonService.GetEnglishLessons();
        else if (keyboard_layout === "TH")
            lessons = this.lessonService.GetThaiLessons();
        else
        {
            console.log("the keyboard layout for this lesson was not found");
            return;
        }
        
        if (this.customLessons && this.customLessons.find(x => x.identifier === keyboard_layout))
            lessons.push(this.customLessons.find(x => x.identifier === keyboard_layout));

        return lessons;
    }

    arrayRemove = (arr, name, keyboard_layout) =>
    { return arr.filter(
        function(x){ 
            return !(x.lesson === name && x.identifier === keyboard_layout)}
        );
    }

    setCustomLessons(data)
    {
        this.customLessons = data;
    }

    addCustomLesson = (name, identifier, data) =>
    {
        var hasLessonAsNumber = (Number(name) !== undefined) && (this.getLesson(identifier,Number(name)) !== undefined);
        var hasLessonAsText = this.getLesson(identifier,name);

        if(!hasLessonAsNumber && !hasLessonAsText) 
        {
            this.customLessons.push({lesson:name.trim(), newCharacters: [], text: data, identifier: identifier});
        }
        else
            this.addCustomLesson(name+"_1",identifier,data);

        return name;
    }

    updateCustomLesson = (name, identifier, data) =>
    {
        var lessonToUpdate = this.customLessons.find(x => x.lesson === name && x.identifier === identifier);
        lessonToUpdate.text = data;    
    }

    deleteCustomLesson = (name, identifier) =>
    {
        this.customLessons = this.arrayRemove(this.customLessons, name, identifier);
        var bestResult = this.bestResult.find(x => x.identifier === identifier);

        if(bestResult)
        {
            bestResult.result = bestResult.result.filter(x => x.lesson !== name);
        }
    }

    getBestResultforLesson = (keyboard_layout, lesson) => {
       var result =  this.bestResult.find(x => x.identifier === keyboard_layout).result.find(y => y.lesson === lesson);
       return result;
    }

    getBestResult = () =>
    {
        return this.bestResult;
    }

    setBestResult = (keyboard_layout, lesson, result) => {
        var item = this.bestResult.find(x => x.identifier === keyboard_layout).result.find(y => y.lesson === lesson);
        if(item)
            item = result;
        else
            this.bestResult.find(x => x.identifier === keyboard_layout).result.push(result);
    }

    setBestResultOverwrite = (inResult) =>
    {
        this.bestResult = inResult;
    }

    saveState = (user, keyboard_layout, isPlaySound, isShowColor, isShowKeyboardLabels) => {
        if (user) {
            var obj_toSave = {keyboard_layout: keyboard_layout, isPlaySound: isPlaySound,isShowColor : isShowColor, isShowKeyboardLabels: isShowKeyboardLabels, results: this.bestResult, customLessons: this.customLessons};
            this.user_doc(user.uid).set(obj_toSave);
        }
    }

    getState = (user) => {
        if(user)
        {
            return this.user_doc(user.uid).get();
        }
    }    
}

export default DataService;