import { DefaultLayout } from "./layouts";
import KeyboardHolder from './components/keyboard-area/KeyboardHolder'
import TermsAndCondition from './components/layout/TermsAndCondition'

export default 
[
    {
        path: "/",
        exact: true,
        layout: DefaultLayout,
        component: KeyboardHolder
    },  
    {
        path: "/terms-and-conditions",
        exact: true,
        layout: DefaultLayout,
        component: TermsAndCondition
    }
]