import React from 'react';

class CallToAction extends React.Component {
    render() {
        return (
            <div className="call-to-action">
            <a href="https://hirequalitysoftware.com">
			<img style={{display:'block', margin: 'auto', width: '500px'}} className="img-responsive" alt="Hire Quality Software" src="img/Software Horizontal.jpg" />
            </a>
		</div>
        )
    }
}

export default CallToAction;