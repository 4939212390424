import React from 'react';
import {connect } from 'react-redux'

class LessonTimer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {startTime: Date.now(), currentTime: Date.now() };
    }

    componentDidMount()
    {
        this.startTicking();
    }

    componentWillUnmount()
    {
        this.stopTicking();
    }


    format = (time) => {
        const pad = (time, length) => {
          while (time.length < length) {
            time = '0' + time;
          }
          return time;
        }
    
        time = new Date(time);
        let h = pad((time.getHours()+(time.getTimezoneOffset()/60) ).toString(),2);
        let m = pad(time.getMinutes().toString(), 2);
        let s = pad(time.getSeconds().toString(), 2);
    
        return `${h}:${m}:${s}`;
      }


    startTicking = () => {
        this.intervalID = setInterval(this.tick, 1000)
    }


    stopTicking = () => {
        clearInterval(this.intervalID)
    }

    tick = () =>
    {   
        this.setState({currentTime : Date.now()});
    }


    render() {
        return (
            <div className="time-box">
            <span id="tickminutes">{this.format(this.state.currentTime - this.state.startTime)}</span>
        </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isFinished: state.isFinished
    }
}

export default connect(mapStateToProps)(LessonTimer);