import React from 'react';
import { connect } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'


class FinishedLesson extends React.Component {
    constructor(props) {
        super(props);
        this.retakeLesson.bind(this);
        this.nextLesson.bind(this);
        this.lessonObj = this.props.dataService.getLesson(this.props.keyboard_layout, this.props.lesson);
    }

    showNextLesson() {
        return this.props.lesson < this.props.dataService.getLessons(this.props.keyboard_layout).length;
    }

    retakeLesson = (event) => {
        event.preventDefault();
        this.props.changeLesson(this.props.lesson);
    }

    nextLesson = (event) => {
        event.preventDefault();
        this.props.changeLesson(this.props.lesson + 1);
    }

    render() {
        return (
            <div className="finishedLesson">
                <div>
                    <div>
                        <h2>  Your Score : {this.lessonObj.identifier ? "" : "Lesson"} {this.props.lesson} {this.props.result.passed ?  <FontAwesomeIcon icon={faCheck} />  :  <FontAwesomeIcon icon={faTimes}/> } </h2>
                        <br />
                        <table>
                            <tbody>
                            <tr>
                                <td>
                                    Accuracy
                            </td>
                                <td>
                                    {this.props.result.accuracy}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    Time elapsed
                            </td>
                                <td>
                                    {this.props.result.timeElapsed}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    WPM
                            </td>
                                <td>
                                    {this.props.result.wpm}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <div style={{paddingTop: '20px'}}>
                            <button onClick={this.retakeLesson} type="button" className="btn btn-primary btn-lg">
                                Retry this lesson
                        </button>
                        {this.showNextLesson() ?
                                <button style={{marginLeft: '10px'}}onClick={this.nextLesson} type="button" className="btn btn-primary btn-lg">
                                    Go to next lesson
                        </button> : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        result: state.result,
        keyboard_layout: state.keyboard_layout,
        dataService: state.dataService
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        changeLesson: (lesson) => {
            dispatch({ type: "CHANGE_LESSON", lesson })
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FinishedLesson);