class KeyboardMapService
{
    GetThaiKeyboardMap(value) {
        return { 
            specialCharacters: ['ิ', 'ื', '้', '่', 'ั', 'ี', 'ุ', 'ึ', 'ำ', '๋', 'ํ', '๊', 'ู', '็', '์'],
            keys:[
                {code:192, location:1, title:['%','_'], classref:['symbol','color-light-purple'], showCharacters:2},
                {code:49, location:2, title:['+','ๅ'], classref:['symbol','color-light-purple',], showCharacters:2},
                {code:50, location:3, title:['๑','/'], classref:['symbol','color-purple'], showCharacters:2},
                {code:51, location:4, title:['๒','-'], classref:['symbol','color-light-blue'], showCharacters:2},
                {code:52, location:5, title:['๓','ภ'], classref:['symbol','color-light-green'], showCharacters:2},
                {code:53, location:6, title:['๔','ถ'], classref:['symbol','color-light-green'], showCharacters:2},
                {code:54, location:7, title:['ู','ุ'], classref:['symbol','color-yellow'], showCharacters:2},
                {code:55, location:8, title:['฿','ึ'], classref:['symbol','color-yellow'], showCharacters:2},
                {code:56, location:9, title:['๕','ค'], classref:['symbol','color-redBrown'], showCharacters:2},
                {code:57, location:10, title:['๖','ต'], classref:['symbol','color-light-brown'], showCharacters:2},
                {code:48, location:11, title:['๗','จ'], classref:['symbol','color-pink'], showCharacters:2},
                {code:189, location:12, title:['๘','ข'], classref:['symbol','color-pink'], showCharacters:2},
                {code:187, location:13, title:['๙','ช'], classref:['symbol','color-pink'], showCharacters:2},
                {code:8, location:14, title:['delete'], classref:['delete','lastitem', 'color-white'], showCharacters:1},
                {code:9, location:15, title:['tab'], classref:['tab','color-white'], showCharacters:1},
                {code:81, location:16, title:['๐','ๆ'], classref:['symbol','color-light-purple'], showCharacters:2},
                {code:87, location:17, title:['"','ไ'], classref:['symbol','color-purple'], showCharacters:2},
                {code:69, location:18, title:['ฎ','ำ'], classref:['symbol','color-light-blue'], showCharacters:2},
                {code:82, location:19, title:['ฑ','พ'], classref:['symbol','color-light-green'], showCharacters:2},
                {code:84, location:20, title:['ธ','ะ'], classref:['symbol','color-light-green'], showCharacters:2},
                {code:89, location:21, title:['ํํ','ั'], classref:['symbol','color-yellow'], showCharacters:2},
                {code:85, location:22, title:['๊','ี'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:73, location:23, title:['ณ','ร'], classref:['symbol','color-redBrown'],showCharacters:2},
                {code:79, location:24, title:['ฯ','น'], classref:['symbol','color-light-brown'],showCharacters:2},
                {code:80, location:25, title:['ญ','ย'], classref:['symbol','color-pink'],showCharacters:2},
                {code:26, location:26, title:['ฐ','บ'], classref:['symbol','color-pink'],showCharacters:2},
                {code:219, location:27, title:[',','ล'], classref:['symbol','color-pink'],showCharacters:2},
                {code:220, location:54, title:['ฅ','ฃ'], classref:['symbol','color-pink','lastitem'],showCharacters:2},
                {code:20, location:29, title:['caps-lock'], classref:['capslock','color-white'],showCharacters:1},
                {code:65, location:30, title:['ฤ','ฟ'], classref:['symbol','color-light-purple'],showCharacters:2},
                {code:83, location:31, title:['ฆ','ห'], classref:['symbol','color-purple'],showCharacters:2},
                {code:68, location:32, title:['ฏ','ก'], classref:['symbol','color-light-blue'],showCharacters:2},
                {code:70, location:33, title:['โ','ด'], classref:['symbol','color-light-green','keyboard-marker'],showCharacters:2},
                {code:71, location:34, title:['ฌ','เ'], classref:['symbol','color-light-green'],showCharacters:2},   
                {code:72, location:35, title:['็','้'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:74, location:36, title:['๋','่'], classref:['symbol','color-yellow','keyboard-marker'],showCharacters:2},
                {code:75, location:37, title:['ษ','า'], classref:['symbol','color-redBrown'],showCharacters:2},
                {code:76, location:38, title:['ศ','ส'], classref:['symbol','color-light-brown'],showCharacters:2},
                {code:186, location:39, title:['ซ','ว'], classref:['symbol','color-pink'],showCharacters:2},
                {code:222, location:40, title:['.','ง'], classref:['symbol','color-pink'],showCharacters:2},
                {code:13, location:41, title:['enter'], classref:['return','lastitem','color-white'],showCharacters:1},
                {code:16, location:42, title:['shift'], classref:['left-shift','color-white'],showCharacters:1},
                {code:90, location:43, title:['(','ผ'], classref:['symbol','color-light-purple'],showCharacters:2},
                {code:88, location:44, title:[')','ป'], classref:['symbol','color-purple'],showCharacters:2},
                {code:67, location:45, title:['ฉ','แ'], classref:['symbol','color-light-blue'],showCharacters:2},
                {code:86, location:46, title:['ฮ','อ'], classref:['symbol','color-light-green'],showCharacters:2},
                {code:66, location:47, title:['ฺ','ิ'], classref:['symbol','color-light-green'],showCharacters:2},
                {code:78, location:48, title:['์','ื'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:77, location:49, title:['?','ท'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:188, location:50, title:['ฒ','ม'], classref:['symbol','color-redBrown'],showCharacters:2},
                {code:190, location:51, title:['ฬ','ใ'], classref:['symbol','color-light-brown'],showCharacters:2},
                {code:191, location:52, title:['ฦ','ฝ'], classref:['symbol','color-pink'],showCharacters:2},
                {code:16, location:53, title:['shift'], classref:['right-shift','lastitem','color-white'],showCharacters:1},
                {code:32, location:54, title:['space',' '], classref:['space','lastitem','color-white'],showCharacters:1}
        ]}                                    
    }

    GetEnglishKeyboardMap(value) {
        return { 
            specialCharacters: [],
            keys:[
                {code:192, location:1, title:['~','`'], classref:['symbol','color-light-purple'],showCharacters:2},
                {code:49, location:2, title:['!','1'], classref:['symbol','color-light-purple'],showCharacters:2},
                {code:50, location:3, title:['@','2'], classref:['symbol','color-purple'],showCharacters:2},
                {code:51, location:4, title:['#','3'], classref:['symbol','color-light-blue'],showCharacters:2},
                {code:52, location:5, title:['$','4'], classref:['symbol','color-light-green'],showCharacters:2},
                {code:53, location:6, title:['%','5'], classref:['symbol','color-light-green'],showCharacters:2},
                {code:54, location:7, title:['^','6'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:55, location:8, title:['&','7'], classref:['symbol','color-yellow'],showCharacters:2},
                {code:56, location:9, title:['*','8'], classref:['symbol','color-redBrown'],showCharacters:2},
                {code:57, location:10, title:['(','9'], classref:['symbol','color-light-brown'],showCharacters:2},
                {code:48, location:11, title:[')','0'], classref:['symbol','color-pink'],showCharacters:2},
                {code:189, location:12, title:['_','-'], classref:['symbol','color-pink'],showCharacters:2},
                {code:187, location:13, title:['+','='], classref:['symbol','color-pink'],showCharacters:2},
                {code:8, location:14, title:['delete'], classref:['delete','lastitem', 'color-white'],showCharacters:1},
                {code:9, location:15, title:['tab'], classref:['tab','color-white'],showCharacters:1},
                {code:81, location:16, title:['Q','q'], classref:['letter','color-light-purple'],showCharacters:1},
                {code:87, location:17, title:['W','w'], classref:['letter','color-purple'],showCharacters:1},
                {code:69, location:18, title:['E','e'], classref:['letter','color-light-blue'],showCharacters:1},
                {code:82, location:19, title:['R','r'], classref:['letter','color-light-green'],showCharacters:1},
                {code:84, location:20, title:['T','t'], classref:['letter','color-light-green'],showCharacters:1},
                {code:89, location:21, title:['Y','y'], classref:['letter','color-yellow'],showCharacters:1},
                {code:85, location:22, title:['U','u'], classref:['letter','color-yellow'],showCharacters:1},
                {code:73, location:23, title:['I','i'], classref:['letter','color-redBrown'],showCharacters:1},
                {code:79, location:24, title:['O','o'], classref:['letter','color-light-brown'],showCharacters:1},
                {code:80, location:25, title:['P','p'], classref:['letter','color-pink'],showCharacters:1},
                {code:26, location:26, title:['{',"["], classref:['symbol','color-pink'],showCharacters:2},
                {code:219, location:27, title:['}',"]"], classref:['symbol','color-pink'],showCharacters:2},
                {code:220, location:54, title:['|','\\'], classref:['symbol','color-pink'],showCharacters:2},
                {code:20, location:29, title:['caps-lock'], classref:['capslock','color-white'],showCharacters:1},
                {code:65, location:30, title:['A','a'], classref:['letter','color-light-purple'],showCharacters:1},
                {code:83, location:31, title:['S','s'], classref:['letter','color-purple'],showCharacters:1},
                {code:68, location:32, title:['D','d'], classref:['letter','color-light-blue'],showCharacters:1},
                {code:70, location:33, title:['F','f'], classref:['letter','color-light-green','keyboard-marker'],showCharacters:1},
                {code:71, location:34, title:['G','g'], classref:['letter','color-light-green'],showCharacters:1},
                {code:72, location:35, title:['H','h'], classref:['letter','color-yellow'],showCharacters:1},
                {code:74, location:36, title:['J','j'], classref:['letter','color-yellow','keyboard-marker'],showCharacters:1},
                {code:75, location:37, title:['K','k'], classref:['letter','color-redBrown'],showCharacters:1},
                {code:76, location:38, title:['L','l'], classref:['letter','color-light-brown'],showCharacters:1},
                {code:186, location:39, title:[':',';'], classref:['symbol','color-pink'],showCharacters:2},
                {code:222, location:40, title:['"','\''], classref:['symbol','color-pink'],showCharacters:2},
                {code:13, location:41, title:['enter'], classref:['return','lastitem','color-white'],showCharacters:1},
                {code:16, location:42, title:['shift'], classref:['left-shift','color-white'],showCharacters:1},
                {code:90, location:43, title:['Z','z'], classref:['letter','color-light-purple'],showCharacters:1},
                {code:88, location:44, title:['X','x'], classref:['letter','color-purple'],showCharacters:1},
                {code:67, location:45, title:['C','c'], classref:['letter','color-light-blue'],showCharacters:1},
                {code:86, location:46, title:['V','v'], classref:['letter','color-light-green'],showCharacters:1},
                {code:66, location:47, title:['B','b'], classref:['letter','color-light-green'],showCharacters:1},
                {code:78, location:48, title:['N','n'], classref:['letter','color-yellow'],showCharacters:1},
                {code:77, location:49, title:['M','m'], classref:['letter','color-yellow'],showCharacters:1},
                {code:188, location:50, title:['<',','], classref:['symbol','color-redBrown'],showCharacters:2},
                {code:190, location:51, title:['>','.'], classref:['symbol','color-light-brown'],showCharacters:2},
                {code:191, location:52, title:['?','/'], classref:['symbol','color-pink'],showCharacters:2},
                {code:16, location:53, title:['shift'], classref:['right-shift','lastitem','color-white'],showCharacters:1},
                {code:32, location:54, title:['space', ' '], classref:['space','lastitem','color-white'],showCharacters:1}
        ]}      
    }
}

export default KeyboardMapService;