import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

app.initializeApp({
	apiKey: "AIzaSyDDCGUprIfdtsvALwhkcLidGxMpW311QSU",
    authDomain: "typingwarrior-41efb.firebaseapp.com",
    databaseURL: "https://typingwarrior-41efb.firebaseio.com",
    projectId: "typingwarrior-41efb",
    storageBucket: "typingwarrior-41efb.appspot.com",
    messagingSenderId: "690973458743",
    appId: "1:690973458743:web:08e7bce5f8874a39171ab2",
    measurementId: "G-WHGZY823DN"
});

class TopNavbar extends React.Component {
	constructor(props) {
		super(props)
		this.state = { isSignedIn: false, user: undefined, dummyCounter: this.props.dummyCounter }
		this.uiConfig = {
			signInFlow: "popup",
			signInOptions: [
				app.auth.GoogleAuthProvider.PROVIDER_ID
			],
			callbacks: {
				signInSuccessWithAuthResult: () => false
			}
		}
		this.signOutOfApplication.bind(this);
		this.logMeOut.bind(this);

		this.fieldValue = app.firestore.FieldValue;
		this.googleProvider = new app.auth.GoogleAuthProvider();
		this.db = app.firestore();
		this.user = uid => this.db.doc(`users/${uid}`);
	}

	componentDidMount() {
		app.auth().onAuthStateChanged((user) => {
			if(user)
			{
				this.setState({isSignedIn: true})
				this.setState({user:user});
				this.props.finishLogin(user);
			}
			else
			{
				this.setState({isSignedIn: false})
				this.setState({user:undefined});
			}
		  });
	}

	componentDidUpdate(prevState, prevProps) {
		if (this.props.user && this.props.dummyCounter === 0) {
			var objectToReturn = undefined;
			this.props.dataService.getState(this.props.user).then((result) => {
				var x = result.data();
				if (x) 
				{
					this.props.dataService.setBestResultOverwrite(x.results);
					objectToReturn = {customLessons: x.customLessons, keyboard_layout: x.keyboard_layout, isPlaySound: x.isPlaySound, isShowColor: x.isShowColor, isShowKeyboardLabels : x.isShowKeyboardLabels }
				}
				else
				{
					this.props.dataService.saveState(this.props.user,this.props.keyboard_layout, this.props.isPlaySound, this.props.isShowColor,this.props.isShowKeyboardLabels);
				}

				this.props.postLogin(objectToReturn);
			});
		}
	}

	logMeOut = () =>
	{
		app.auth().signOut().then(() => {
			this.props.finishLogout();
		});
	}

	// componentWillUnmount()
	// {
	// 	this.unregisterAuthObserver();
	// }

	signOutOfApplication = (event) => {
		event.preventDefault();
		app.auth().signOut();
	}

	render() {
		return (
			<header>
				<nav className="navbar navbar-expand-lg navbar-light">
					<div className="navbar-header">
						<div className="navbar-brand" href="#">
						<Link to={"/"}>
							<img className="img-responsive" src="img/cooltext-357164831269399.png" alt="Logo" />
							</Link>
						</div>
					</div>
					<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

{!this.state.isSignedIn &&
					<div className="collapse navbar-collapse" id="navbarNav">
						<ul className="nav navbar-nav ml-auto">
								<li className="nav-item dropdown">
									<a className="nav-link" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Signin </a>
									<div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
										<StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={app.auth()} />
									</div>
								</li>
						</ul>
					</div>
}

{this.state.isSignedIn &&
						<div className="collapse navbar-collapse" id="navbarNav">
							<ul className="nav navbar-nav ml-auto">
								<li className="nav-item">
										<p className="nav-link" >({this.state.user && this.state.user.email})</p>
									</li>
								<li className="nav-item">
									<a className="nav-link" href="#" onClick={this.logMeOut}>Sign-out</a>
								</li>
							</ul>
						</div>
}
				</nav>
			</header>
		)
	}
}

const mapDispatchToProps = (dispatch) => {
    return {
        finishLogin: (result) => {
            dispatch({ type: "LOGIN", result })
		},
		finishLogout: (result) => {
			dispatch({ type: "LOGOUT", result })
		},
		 postLogin: (result) => {
		 	dispatch({type: "POST_LOGIN", result})
		}
    };
}


const mapStateToProps = (state) => {
    return {
		dataService: state.dataService,
		user: state.user,
		keyboard_layout: state.keyboard_layout,
		dummyCounter: state.dummyCounter,
		isPlaySound: state.isPlaySound,
		isShowColor: state.isShowColor, 
		isShowKeyboardLabels: state.isShowKeyboardLabels
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavbar);