import React from 'react';
import { connect } from 'react-redux';

class ScoreCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {user: this.props.user, keyboard_layout: this.props.keyboard_layout, lesson: this.props.lesson, accuracy: "", timeElapsed: "", wpm: "", passed: "" }
    }

    componentDidUpdate(prevProps, prevState) {
        var result = this.props.dataService.getBestResultforLesson(this.props.keyboard_layout, this.props.lesson);

        if (prevState.user !== this.props.user) {
            this.setState({ user: this.props.user, accuracy: "", timeElapsed: "", wpm: "", passed: "" });
        }

        if (prevState.lesson !== this.props.lesson || prevState.keyboard_layout !== this.props.keyboard_layout) {

            this.setState({ lesson: this.props.lesson });
            this.setState({ keyboard_layout: this.props.keyboard_layout });

            if (result)
                this.setState({ accuracy: result.accuracy, timeElapsed: result.timeElapsed, wpm: result.wpm, passed: result.passed });
            else
                this.setState({ accuracy: "", timeElapsed: "", wpm: "", passed: "" });
        }
        else 
        {
            if (result) {
                if (!(result.accuracy === this.state.accuracy && result.timeElapsed === this.state.timeElapsed && result.wpm === this.state.wpm && this.props.lesson === this.state.lesson))
                    this.setState({ accuracy: result.accuracy, timeElapsed: result.timeElapsed, wpm: result.wpm, passed: result.passed });
            }
        }
    }
    render() {
        return (
            <>
                <div id="scoreboard">
                    <div className="card text-dark">
                        <div className="card-header text-center">
                            Your best score for this lesson
					</div>
                        <div className="card-body">
                            <div className="list-group" style={{ overflow: 'hidden' }}>
                                <div className="list-group-item d-flex justify-content-between align-items-center list-group-item-action">Accuracy: {this.state.accuracy}
                                </div>
                                <div className="list-group-item d-flex justify-content-between align-items-center list-group-item-action">Time elapsed: {this.state.timeElapsed}
                                </div>
                                <div className="list-group-item d-flex justify-content-between align-items-center list-group-item-action">WPM: {this.state.wpm}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{height:'100%', width:'100%', textAlign:'center'}}>
                    <img alt="hands" style={{paddingTop:70}} src="img/hands.png" alt="hands" height="167px" width="250" />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        lesson: state.lesson,
        dataService: state.dataService,
        isFinished: state.isFinished,
        keyboard_layout : state.keyboard_layout,
        user : state.user,
        dummyCounter: state.dummyCounter
    }
}


export default connect(mapStateToProps)(ScoreCard);