import KeyboardMapService from '../../services/KeyboardMapService';
import DataService from '../../services/DataService'

var dataService = new DataService();

const initialState = {
    
    dataService: dataService,
    keyboard: (new KeyboardMapService()).GetEnglishKeyboardMap().keys,
    specialCharacters : (new KeyboardMapService()).GetEnglishKeyboardMap().specialCharacters,
    lesson: 1,
    isStarted: false,
    isFinished: false,
    isCreatingNewLesson: false,
    result: undefined,
    isPlaySound : false,
    isShowColor : false,
    isShowKeyboardLabels : true,
    keyboard_layout : "EN",
    user: undefined,
    isLogged: false,
    dummyCounter: 1,
    editLesson: {}
};


const lessonReducer = (state = initialState, action) => {

    var newState = { ...state };

    switch (action.type) {
        case "CHANGE_LESSON":
            newState.lesson = action.lesson;
            newState.isFinished = false;
            newState.isStarted = false;
            newState.isCreatingNewLesson = false;
            newState.editLesson = {};
        break;

        case "START_LESSON":
            newState.isStarted = true;
            newState.editLesson = {};
            break;

        case "FINISHED_LESSON":
            newState.editLesson = {};
            newState.isFinished = true;
            newState.isStarted = false;
            newState.result = action.result;
            var bestResultForLesson = newState.dataService.getBestResultforLesson(newState.keyboard_layout, newState.lesson);

            if (bestResultForLesson) {
                if ((!bestResultForLesson.passed && action.result.passed) || (bestResultForLesson.passed === action.result.passed && bestResultForLesson.wpm < action.result.wpm)) {
                        bestResultForLesson.passed = action.result.passed;
                        bestResultForLesson.timeElapsed = action.result.timeElapsed;
                        bestResultForLesson.wpm = action.result.wpm;
                        bestResultForLesson.lesson = newState.lesson;
                        bestResultForLesson.accuracy = action.result.accuracy;
                }
            }
            else
            {
                newState.dataService.setBestResult(newState.keyboard_layout,newState.lesson,action.result);
            }

            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
        break;

        case "TOGGLE_COLORS" :
            newState.isShowColor ? newState.isShowColor = false : newState.isShowColor = true;
            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
        break;

        case "TOGGLE_KEYBOARD" :
            newState.isShowKeyboardLabels ? newState.isShowKeyboardLabels = false : newState.isShowKeyboardLabels = true;
            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
        break;

        case "TOGGLE_PLAYSOUND" :
            newState.isPlaySound ? newState.isPlaySound = false : newState.isPlaySound = true;
            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
        break;

        case "SWITCH_KEYBOARD_LAYOUT" :
        newState.editLesson = {};
            newState.keyboard_layout = action.result;
            newState.lesson = 1;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.isCreatingNewLesson = false;
            newState.keyboard = action.result === "EN" ? (new KeyboardMapService()).GetEnglishKeyboardMap().keys : (new KeyboardMapService()).GetThaiKeyboardMap().keys;
            newState.specialCharacters = action.result === "EN" ? (new KeyboardMapService()).GetEnglishKeyboardMap().specialCharacters : (new KeyboardMapService()).GetThaiKeyboardMap().specialCharacters;
            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
        break;

        case "LOGIN" :
            newState.user = action.result;
            newState.dummyCounter = 0;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.isCreatingNewLesson = false;
            newState.editLesson = {};
        break;

        case "POST_LOGIN" :
            newState.dummyCounter = newState.dummyCounter+2;
            newState.editLesson = {};
            if(action.result)
            {
                newState.dataService.setCustomLessons(action.result.customLessons);
                newState.keyboard_layout = action.result.keyboard_layout;
                newState.isPlaySound = action.result.isPlaySound;
                newState.isShowColor = action.result.isShowColor;
                newState.isShowKeyboardLabels = action.result.isShowKeyboardLabels;
                newState.keyboard = action.result.keyboard_layout === "EN" ? (new KeyboardMapService()).GetEnglishKeyboardMap().keys : (new KeyboardMapService()).GetThaiKeyboardMap().keys;
                newState.specialCharacters = action.result.keyboard_layout === "EN" ? (new KeyboardMapService()).GetEnglishKeyboardMap().specialCharacters : (new KeyboardMapService()).GetThaiKeyboardMap().specialCharacters;
            }
        break;
        
        case "LOGOUT" :
            newState.user = undefined;
            newState.dataService = dataService = new DataService();
            newState.lesson = 1;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.dummyCounter++;  
            newState.editLesson = {};
        break;

        case "FORCE_RELOAD" :
            newState.dummyCounter++; 
            newState.editLesson = {};   
        break;

        case "CREATE_NEW_LESSON_TRIGGER" :
            newState.isCreatingNewLesson = true;
            newState.editLesson = {};
        break;

        case "CANCEL_CREATE_NEW_LESSON":
            newState.isCreatingNewLesson = false;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.editLesson = {};
         break;

         case "CREATE_LESSON_COMPLETED":
            newState.isCreatingNewLesson = false;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.lesson = action.name;
            newState.editLesson = {};
            newState.dataService.saveState(newState.user,newState.keyboard_layout, newState.isPlaySound, newState.isShowColor,newState.isShowKeyboardLabels);
         break;

         case "LESSON_DELETED":
            newState.isCreatingNewLesson = false;
            newState.isStarted = false;
            newState.isFinished = false;
            newState.lesson = 1;
            newState.editLesson = {};
         break;

         case "EDIT_LESSON" :
            newState.editLesson = action.lesson;
         break;
            
        default:
            console.log("do nothing");
    }
    return newState;
};

export default lessonReducer;